@import url('https://fonts.googleapis.com/css2?family=Hind&display=swap');

body {
  background: #e8eaf1;
  font-family: 'Hind', Arial, Helvetica, sans-serif;
}

.app {
  min-height: 100vh;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
}

textarea {
  background: #eff1f5;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  outline: none;
  border: none;
  border-radius: 5px;
  padding: 20px;
  font-size: 22px;
}

.preview {
  padding-left: 20px;
  padding-right: 20px;
}

blockquote {
  font-size: 30px;
  background: #efefef;
  border-left: 12px solid rgb(44, 163, 241);
  padding: 20px 30px;
  border-radius: 5px;
  margin: 0;
}
